
import * as Components from "./Psh"

export default function getCustomComponent () {
  return Components
}

export function getShopId () {
  return "psh"
}
