import React, { Component } from 'react';
import './index.scss'
class CoralCafe extends Component {
  render() {
    return (
      <div className="coralcafe">
      </div>
    );
  }
}

export class Header extends Component {
  render() {
    return (
      <div className="order-benefits-wrapper">
        <div className="order-benefits">

          <div className="text">
            {/* <p>Geoge's Pizza</p> */}
            <p>Order Delivery/Togo Below</p>
            {/* <p className="large">Free Delivery</p> */}
            <p className="large">& get 5% Cashback</p>
          </div>


        </div>
      </div>
    );
  }
}

export default CoralCafe;
